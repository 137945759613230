<template>
  <div>
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div class="row">
      <b-tabs v-model="tabIndex" class="content-d-none w-100">
        <b-tab title="Internal Notifications"></b-tab>
        <b-tab title="Customer Notifications"></b-tab>
      </b-tabs>
    </div>
    <div class="row" style="padding-bottom: 10rem">
      <b-tabs class="nav-d-none w-100">
        <b-tab>
          <transition name="slide-fade" mode='out-in'>
          <div v-if="tabIndex === 0" class="mt-5 pt-4 px-4" key="0">
            <div class="row labels-wrapper">
              <div class="col-6 pl-5">
                <p class="notification-label">Notification Type</p>
              </div>
              <div class="col-6 pr-5 d-flex">
                <p class="notification-label m-auto">Action</p>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Departure Email</span>
              </div>
              <div class="col-6 d-flex">
                <label class="switch m-auto">
                  <input :disabled="!canEdit" v-model="internal.departure.email.isActive" name="check-button" type="checkbox"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Departure Phone</span>
              </div>
              <div class="col-6 d-flex">
                <label class="switch m-auto">
                  <input :disabled="!canEdit" v-model="internal.departure.phone.isActive" name="check-button" type="checkbox"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Arrival Email</span>
              </div>
              <div class="col-6 d-flex">
                <label class="switch m-auto">
                  <input :disabled="!canEdit" v-model="internal.arrival.email.isActive" name="check-button" type="checkbox"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Arrival Phone</span>
              </div>
              <div class="col-6 d-flex">
                <label class="switch m-auto">
                  <input :disabled="!canEdit" v-model="internal.arrival.phone.isActive" name="check-button" type="checkbox"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Complete Email</span>
              </div>
              <div class="col-6 d-flex">
                <label class="switch m-auto">
                  <input :disabled="!canEdit" v-model="internal.complete.email.isActive" name="check-button" type="checkbox"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Depart Notify Internal Email(s) (up to 10)</span>
              </div>
              <div class="col-6 px-5">
                <div v-for="(item, index) in this.internal.departure.email.content" :key="index" class="mb-3">
                  <div class="input-group">
                    <input :disabled="!canEdit" v-model="item.value" placeholder="Email" class="form-control"/>
                    <div v-if="canEdit" @click="removeInput('departure', 'email', index)" class="input-group-append input-remove"><span class="input-group-text">x</span></div>
                  </div>
                  <div v-if="item.error" class="text-error d-flex">{{ item.error }}</div>
                </div>
                <b-button v-if="canAddInput('departure', 'email')" @click="addNewEmptyInput('departure', 'email')" class="px-5 button-add">Add</b-button>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Depart Notify Internal Phone(s) (up to 10)</span>
              </div>
              <div class="col-6 px-5">
                <div v-for="(item, index) in this.internal.departure.phone.content" :key="index" class="mb-3">
                  <div class="input-group">
                    <input v-model="item.value" placeholder="Phone" class="form-control"/>
                    <div v-if="canEdit" @click="removeInput('departure', 'phone', index)" class="input-group-append input-remove"><span class="input-group-text">x</span></div>
                  </div>
                  <div v-if="item.error" class="text-error d-flex">{{ item.error }}</div>
                </div>
                <b-button v-if="canAddInput('departure', 'phone')" @click="addNewEmptyInput('departure', 'phone')" class="px-5 button-add">Add</b-button>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Arrival Notify Internal Email(s) (up to 10)</span>
              </div>
              <div class="col-6 px-5">
                <div v-for="(item, index) in this.internal.arrival.email.content" :key="index" class="mb-3">
                  <div class="input-group">
                    <input v-model="item.value" placeholder="Email" class="form-control"/>
                    <div v-if="canEdit" @click="removeInput('arrival', 'email', index)" class="input-group-append input-remove"><span class="input-group-text">x</span></div>
                  </div>
                  <div v-if="item.error" class="text-error d-flex">{{ item.error }}</div>
                </div>
                <b-button v-if="canAddInput('arrival', 'email')" @click="addNewEmptyInput('arrival', 'email')" class="px-5 button-add">Add</b-button>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Arrival Notify Internal Phone(s) (up to 10)</span>
              </div>
              <div class="col-6 px-5">
                <div v-for="(item, index) in this.internal.arrival.phone.content" :key="index" class="mb-3">
                  <div class="input-group">
                    <input v-model="item.value" placeholder="Phone" class="form-control"/>
                    <div v-if="canEdit" @click="removeInput('arrival', 'phone', index)" class="input-group-append input-remove"><span class="input-group-text">x</span></div>
                  </div>
                  <div v-if="item.error" class="text-error d-flex">{{ item.error }}</div>
                </div>
                <b-button v-if="canAddInput('arrival', 'phone')" @click="addNewEmptyInput('arrival', 'phone')" class="px-5 button-add">Add</b-button>
              </div>
            </div>
            <div class="row notification-type-wrapper mt-4 py-4">
              <div class="col-6 d-flex">
                <span class="notification-type-label my-auto">Complete Notify Internal Email(s) (up to 10)</span>
              </div>
              <div class="col-6 px-5">
                <div v-for="(item, index) in this.internal.complete.email.content" :key="index" class="mb-3">
                  <div class="input-group">
                    <input v-model="item.value" placeholder="Email" class="form-control"/>
                    <div v-if="canEdit" @click="removeInput('complete', 'email', index)" class="input-group-append input-remove"><span class="input-group-text">x</span></div>
                  </div>
                  <div v-if="item.error" class="text-error d-flex">{{ item.error }}</div>
                </div>
                <b-button v-if="canAddInput('complete', 'email')" @click="addNewEmptyInput('complete', 'email')" class="px-5 button-add">Add</b-button>
              </div>
            </div>
            <div style="padding-bottom: 5rem"></div>
            <div class="page-footer">
              <div class="row justify-content-center">
                <b-button v-if="canEdit" @click="updateInternalNotifications" class="px-5">Update</b-button>
              </div>
            </div>
          </div>
          <div v-if="tabIndex === 1" key="1">
            <VueBootstrap4Table 
              :classes="classes"
              :rows="list" 
              :columns="columns" 
              :config="config"
              :total-rows="listTotal"
              @on-change-query="onChangeQuery"
            >
              <template slot="empty-results">
                <div
                  v-if="!isLoading"
                  class="bold-text"
                >No records found</div>
                <div
                  v-if="isLoading"
                  class="row justify-content-center mt-3"
                ><div class="loading"></div></div>
              </template>
              <template slot="paginataion" slot-scope="props">
                {{props.currentPageRowsLength}} of {{props.originalRowsLength}}
              </template>
              <template slot="sms-notification" slot-scope="props">
                <div :class="{'green-text': props.row.sendNotificationWhatsapp}">
                  {{ props.row.sendNotificationWhatsapp ? "Yes" : "No" }}
                </div>
              </template>
              <template slot="email-notification" slot-scope="props">
                <div :class="{'green-text': props.row.sendNotificationEmail}">
                  {{ props.row.sendNotificationEmail ? "Yes" : "No" }}
                </div>
              </template>
              <template slot="action" slot-scope="props">
                <router-link :to="'/notification-management/customer-detail/' + props.row.id" class="m-0 p-0 link-underscore-red justify-content-start">Detail View</router-link>
              </template>
              <template slot="paginataion-previous-button"><i class="arrow my-auto left blur"></i></template>
              <template slot="paginataion-next-button"><i class="arrow my-auto right blur"></i></template>
            </VueBootstrap4Table>
          </div>
          </transition>
        </b-tab>
      </b-tabs>
    </div>
    </div>
  </div>
</template>
<script>
import clone from "lodash/clone"
import cloneDeep from "lodash/cloneDeep"
import * as notificationActions from "../../store/modules/notification/actions";
import * as uiActions from "../../store/modules/_shared/ui/actions";
import { emailValidate, phoneValidate } from "../../utils/helpers"
export default {
  name: "NotificationManagement",
  props: ['tab'],
  components: {
    VueBootstrap4Table: () => import("@/components/ui/VueBootstrap4Table/VueBootstrap4Table"),
  },
  watch: {
    'queryParams.page': {
      handler(){
        // this.getData();
      }
    },
    'queryParams.per_page': {
      handler(){
        // this.getData();
      }
    },
    tabIndex: {
      handler(newValue){
        this.onTabsChanged(newValue);
      }
    },
    sort: {
      deep: true,
      handler(n, o){
        if(JSON.stringify(n) !== JSON.stringify(o)){
          // this.getData();
        }
      }
    }
  },
  computed: {
    sort: function(){
      return Object.assign({}, this.queryParams.sort[0]);
    }
  },
  data(){
    return{
      isLoading: false,
      tabIndex: this.tab || 0,
      type: this.tabIndex === 0 ? 'internal' : 'customer',
      currentUser: this.$store.getters.getUser,
      canEdit: false,
      list: [],
      listTotal: 0,
      internal: {
        departure: {
          email: {
            isActive: false,
            content: [],
          },
          phone: {
            isActive: false,
            content: [],
          }
        },
        arrival: {
          email: {
            isActive: false,
            content: [],
          },
          phone: {
            isActive: false,
            content: [],
          }
        },
        complete: {
          email: {
            isActive: false,
            content: [],
          },
        }
      },
      columns: [{
        label: "id",
        visibility: false,
        name: "id",
        sort: true,
      },
      {
        label: "Customer No",
        name: "customerNo",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Customer Name",
        name: "customerName",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "Ship to Code",
        name: "shipToCode",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        sort: true,
      },
      {
        label: "SMS Notification",
        // Temporary use back sendNotificationWhatsapp, 
        // might switch back to whatsapp in the future
        name: "sendNotificationWhatsapp",
        column_text_alignment: "text-left",
        row_text_alignment: "text-center",
        sort: true,
        slot_name: "sms-notification",
        width: "180px"
      },
      {
        label: "Email Notification",
        name: "sendNotificationEmail",
        column_text_alignment: "text-left",
        row_text_alignment: "text-center",
        sort: true,
        slot_name: "email-notification",
        width: "180px"
      },

      {
        label: "Action",
        name: "action",
        column_text_alignment: "text-left",
        row_text_alignment: "text-left",
        width: "15%"
      }],
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        page: 1,
        per_page: 10,
        preservePageOnDataChange: false,
        pagination: true,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          placeholder: "Search Customer"
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      classes: {
        table: {
          "spci-table": true,
        }
      }
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getData();
    },
    onTabsChanged(index){
      if (index === 0) {
        document.getElementById('header-route-name').textContent = 'Internal Notifications';
        this.type = 'internal'
      } else if (index === 1) {
        document.getElementById('header-route-name').textContent = 'Customer Notifications';
        this.type = 'customer'
      }
      this.getData()
    },
    getData() {
      this.isLoading = true;
      // const page = this.queryParams.page;
      // const limit = this.queryParams.per_page;
      // const search = this.queryParams.global_search;
      // let status;
      // const filter = {page: page, limit: limit, status: status, search: search, orderCol: orderCol, desc: desc};
      let apiToCall = null
      if (this.type === 'customer') apiToCall = this.$store.dispatch(notificationActions.CUSTOMER_LIST_REQUEST, {})
      if (this.type === 'internal') apiToCall = this.$store.dispatch(notificationActions.INTERNAL_NOTIFICATIONS_REQUEST, {})
      apiToCall.then(res => {
        if (res.status === "ok" && this.type == 'customer') {
          this.list = res.response;
          this.listTotal = res.response.length;
        } else if (res && this.type == 'internal') {
          this.populateInternalNotification(res.response)
        } else {
          throw(res)
        }
      }).catch(err => {
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: err.errorMessage
        })
      }).finally(() => {this.isLoading = false;})
    },
    populateInternalNotification(res) {
      if (res.length > 0) {
        this.internal.departure.email.content = []
        this.internal.departure.phone.content = []
        this.internal.arrival.email.content = []
        this.internal.arrival.phone.content = []
        this.internal.complete.email.content = []
        
        let departureEmail = res.find(el => el.code == 'internal_email_depart')
        this.internal.departure.email.isActive = departureEmail.value
        departureEmail.content.forEach(value => this.internal.departure.email.content.push({value}))

        let departurePhone = res.find(el => el.code == 'internal_phone_depart')
        this.internal.departure.phone.isActive = departurePhone.value
        departurePhone.content.forEach(value => this.internal.departure.phone.content.push({value}))

        let arrivalEmail = res.find(el => el.code == 'internal_email_arrive')
        this.internal.arrival.email.isActive = arrivalEmail.value
        arrivalEmail.content.forEach(value => this.internal.arrival.email.content.push({value}))

        let arrivalPhone = res.find(el => el.code == 'internal_phone_arrive')
        this.internal.arrival.phone.isActive = arrivalPhone.value
        arrivalPhone.content.forEach(value => this.internal.arrival.phone.content.push({value}))

        let completeEmail = res.find(el => el.code == 'internal_email_complete')
        console.log(completeEmail)
        this.internal.complete.email.isActive = completeEmail.value
        completeEmail.content.forEach(value => this.internal.complete.email.content.push({value}))
      }
    },
    canAddInput(type1, type2) {
      if (!this.canEdit) return false
      let inputLessThan10 = false
      let isLatestInputNotEmpty = false

      let arrayToCheck = this.internal[type1][type2].content
      if (arrayToCheck.length > 0) {
        inputLessThan10 = arrayToCheck.length < 10
        let latestArrayItem = arrayToCheck[arrayToCheck.length-1]
        isLatestInputNotEmpty = latestArrayItem.value != null && latestArrayItem.value != ""
      } else {
        return true
      }
      return inputLessThan10 && isLatestInputNotEmpty
    },
    addNewEmptyInput(type1, type2) {
      this.internal[type1][type2].content.push({value: null})
    },
    removeInput(type1, type2, index) {
      this.internal[type1][type2].content.splice(index, 1)
    },
    updateInternalNotifications() {
      this.isLoading = true;
      this.removeEmptyFields()
      
      if (this.isRequiredEmpty()) {
        this.isLoading = false
        let message = `Error found: Email/phone required when the notification type is enabled.`
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: message,
        })
        return
      }

      let invalidFields = this.getInvalidFields()
      if (invalidFields.faultyEmailNum > 0 || invalidFields.faultyPhoneNum > 0) {
        this.isLoading = false
        let message = `Error found: there is/are ${invalidFields.faultyEmailNum} invalid email 
          and ${invalidFields.faultyPhoneNum} invalid phone`
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: message,
        })
        return
      }
      let payload = cloneDeep(this.getPayloadForInternalNotification())
      this.$store.dispatch(notificationActions.INTERNAL_NOTIFICATIONS_POST_REQUEST, payload).then(res =>{
        if (res) {
          this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
            message: "Update successfully",
          })
          this.populateInternalNotification(res.response)
        }
      }).finally(() => this.isLoading = false)
    },
    removeEmptyFields() {
      this.internal.departure.email.content = this.internal.departure.email.content
        .filter(el => el.value !== "" && el.value !== null)
      this.internal.departure.phone.content = this.internal.departure.phone.content
        .filter(el => el.value !== "" && el.value !== null)
      this.internal.arrival.email.content = this.internal.arrival.email.content
        .filter(el => el.value !== "" && el.value !== null)
      this.internal.arrival.phone.content = this.internal.arrival.phone.content
        .filter(el => el.value !== "" && el.value !== null)
      this.internal.complete.email.content = this.internal.complete.email.content
        .filter(el => el.value !== "" && el.value !== null)
    },
    isRequiredEmpty() {
      let isRequiredEmpty = false
      if (this.internal.departure.email.isActive 
       && this.internal.departure.email.content.length <= 0) isRequiredEmpty = true
      if (this.internal.departure.phone.isActive
       && this.internal.departure.phone.content.length <= 0) isRequiredEmpty = true
      if (this.internal.arrival.email.isActive
       && this.internal.arrival.email.content.length <= 0) isRequiredEmpty = true 
      if (this.internal.arrival.phone.isActive
       && this.internal.arrival.phone.content.length <= 0) isRequiredEmpty = true
      if (this.internal.complete.email.isActive
       && this.internal.complete.email.content.length <= 0) isRequiredEmpty = true
      return isRequiredEmpty
    },
    getInvalidFields() {
      let errorsFound = {
        faultyPhoneNum: 0,
        faultyEmailNum: 0,
      }
      this.internal.departure.email.content.forEach(el => {
        el.error = null
        if (el.value && !emailValidate(el.value)) {
          el.error = "Invalid email !"
          errorsFound.faultyEmailNum += 1
        }
      })
      this.internal.arrival.email.content.forEach(el => {
        el.error = null
        if (el.value &&!emailValidate(el.value)) {
          el.error = "Invalid email !"
          errorsFound.faultyEmailNum += 1
        }
      })
      this.internal.complete.email.content.forEach(el => {
        el.error = null
        if (el.value &&!emailValidate(el.value)) {
          el.error = "Invalid email !"
          errorsFound.faultyEmailNum += 1
        }
      })
      this.internal.departure.phone.content.forEach(el => {
        el.error = null
        if (el.value &&!phoneValidate(el.value)) {
          el.error = "Invalid phone ! (Please omit '-')"
          errorsFound.faultyPhoneNum += 1
        }
      })
      this.internal.arrival.phone.content.forEach(el => {
        el.error = null
        if (el.value &&!phoneValidate(el.value)) {
          el.error = "Invalid phone ! (Please omit '-')"
          errorsFound.faultyPhoneNum += 1
        }
      })
      return errorsFound
    },
    getPayloadForInternalNotification() {
      let departureEmailContent = this.internal.departure.email.content
        .map(el => el.value)
      let departurePhoneContent = this.internal.departure.phone.content
        .map(el => el.value)
      let arrivalEmailContent = this.internal.arrival.email.content
        .map(el => el.value)
      let arrivalPhoneContent = this.internal.arrival.phone.content
        .map(el => el.value)
      let completeEmailContent = this.internal.complete.email.content
        .map(el => el.value)
      return { notification: [ //Follow API structure
        {
          code: "internal_email_depart",
          value: this.internal.departure.email.isActive,
          content: departureEmailContent,
        },
        {
          code: "internal_phone_depart",
          value: this.internal.departure.phone.isActive,
          content: departurePhoneContent,
        },
        {
          code: "internal_email_arrive",
          value: this.internal.arrival.email.isActive,
          content: arrivalEmailContent,
        },
        {
          code: "internal_phone_arrive",
          value: this.internal.arrival.phone.isActive,
          content: arrivalPhoneContent,
        },
        {
          code: "internal_email_complete",
          value: this.internal.complete.email.isActive,
          content: completeEmailContent,
        },
      ]}
    },
  },
  mounted() {
    this.canEdit = this.currentUser.isAdmin
    this.$nextTick(() => {
      this.tabIndex = clone(this.$route.params.index ? +this.$route.params.index : 0)
      this.onTabsChanged(this.tabIndex)
    })
  }
}
</script>
<style lang="scss" scoped>
.notification-label{
  color: #abafb3;
  font-weight: 500;
  font-size: 1.4rem;
}
.labels-wrapper{
  border-bottom: .1rem solid rgba(120, 130, 140, .13);
}
.notification-type-wrapper{
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  >div:first-child {
    border-right: 0.5px solid #DCDCDC;
  }
}
.notification-type-label{
  color: black;
  font-weight: 600;
}
input.form-control{
  background-color: #F7F7F7!important;
  border: 1px solid #E9E9EB;
  color: #6F6F6F;
  padding: 5px 10px;
  min-height: auto;
}
.slider-disabled {
  cursor: auto;
}
.input-remove {
  cursor: pointer;
  span {
    color: white;
    background-color: red;
  }
}
.button-add {
  background-color: green;
  border-color: green;
  width: 100%;
}
.text-error {
  color: red;
}
.green-text {
  color: #22D60E;
}
</style>